import React, {useState, useEffect} from 'react'
import { db } from './firebase';
import {Link} from 'react-router-dom';
import {
  AiOutlineArrowLeft as AiOut
} from 'react-icons/ai'; 
import './style.css';
import {
  useParams
} from 'react-router-dom';
import { motion } from 'framer-motion';
import logo from './logo250.png'

const Records = () => {



  const [records, setRecords] = useState('');
  const [loading, setLoading] = useState(true);

  
  useEffect(() => {
    db.collection('records').onSnapshot(snapshot => (
      setRecords(snapshot.docs.map(doc => ({
        id: doc.id,
        record: doc.data()
      }))
      )
    ))
    setLoading(false)
  }, [])

  useEffect(() => {
    if(records.length > 0) {
      setLoading(false);
    }
  }, [records])
  


  

  return (
    <>


{
          loading ? (
          <motion.div 
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 1}}
          className='container'>
            <img src={logo} alt="vahaa-logo"
            style={{width: '250px', display: 'block', margin: 'auto'}}/>
          </motion.div>
          ) : (
<>
    <div className="header">
    <div className='back'>
        <Link to='/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51'>
            <AiOut className='icon' />
        </Link>
    </div>
    <h1
    style={{
      textAlign: 'center', 
      marginRight: '46%'
  }}
    >Records</h1>
  </div>
    <div className='container'>
    {
      records && records.map(({id, record}) => (
        <div  className='inquire' key={id}>
           <img src={record.License} alt="License" style={{
            width: 310, height: 200
          }} />
          <p className="inquireText" style={{
            fontWeight: "200", fontSize: 36
          }}>{record.name}</p>
          <p className="inquireText" style={{
            color: "#AEB5BC", fontSize: 14
          }}>{record.addressLine}</p>
          <p className="inquireText" style={{
            color: "#AEB5BC", fontSize: 14
          }}>Phone: {record.phone}</p>
          <p className="inquireText" style={{
            color: "#AEB5BC", fontSize: 14
          }}>Email: {record.email}</p>
          <p className="inquireText" style={{
            color: "#AEB5BC", fontSize: 14
          }}>Car Rented: {record.carName}</p>
          <p className="inquireText" style={{
            color: "#AEB5BC", fontSize: 14
          }}>Picked Up: {record.pickupDate}, {record.pickupTime} </p>
              <p className="inquireText" style={{
                color: "#AEB5BC", fontSize: 14
              }}>Returned On: {record.returnDate}, {record.returnTime} </p>
            
        </div>

      ))
    }
    </div>
    </>
          )
  }
    </>
  )
}

export default Records;