import React, {useState, useEffect} from 'react'
import {
  Link, 
  useParams, 
  Navigate
} from 'react-router-dom';
import {db} from '../firebase';
import { motion } from 'framer-motion';
import  logo  from './logo250.png';

const DeleteCar = () => {
  const {id} = useParams();
  const [carName, setCarName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    db.collection('cars').doc(id).get().then(doc => {
      setCarName(doc.data().name);
      setLoading(false);
    })
  }, []);

  const deleteCar = () => {
    db.collection('cars').doc(id).delete().then(() => {
      alert('Car deleted successfully');
      Navigate('/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/cars');
    })
  }

  return (
    <>
     
        {
          loading ? (
          <motion.div 
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 1}}
          className='container'>
            <img src={logo} alt="vahaa-logo"
            style={{width: '250px', display: 'block', margin: 'auto'}}/>
          </motion.div>
          ) : (
          <>
          <div className="header">
            <h1>Delete Car</h1>
          </div>
          <div className="container">
            <div className='widget'>
              <h1>Are you sure you want to delete {carName} (this action is not reversable)</h1>
              <Link to='/cars'>
                <button
              className='button'
              onClick={deleteCar}>Delete
              </button>
              </Link>
              <Link to="f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/cars">
                <button className='button'>Cancel</button>
              </Link>
             
            </div>
            </div>
            </>
          )
        }

    </>
  )
}

export default DeleteCar