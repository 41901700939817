import React, {
    useState,
    useEffect
} from 'react'
import {db, storage} from '../firebase'; 
import { useParams, Navigate } from 'react-router-dom'

function EditCar() {

    const {id} = useParams();


    const [loading, setLoading] = useState(true);
    const [carName, setCarName] = useState('');
    const [carPrice, setCarPrice] = useState('');
    const [carImageURL, setCarImageURL] = useState('');
    const [carDescription, setCarDescription] = useState('');
   
    
    useEffect(() => {
        db.collection('cars').doc(id).get().then(doc => {
            setCarName(doc.data().name)
            setCarPrice(doc.data().price)
            setCarDescription(doc.data().description)
        })
    }, [])

    useEffect(() => {
        if(carName.length > 0) {
            setLoading(false);
        }
    }, [carName])



    const handleSubmit = (e) => {
        e.preventDefault();
        db.collection('records').add({
            name: carName,
            price: carPrice,
            description: carDescription,
            image: carImageURL,
        })
        .then(() => {
            alert('Car added successfully')
            Navigate('/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/cars');
        })
        .catch((error) => {
            alert(error.message)
        })
    }

    const uploadImageHandler = (e) => {
        e.preventDefault();
        const image = e.target.files[0];
        const uploadTask = storage.ref(`images/${image.name}`).put(image);
        uploadTask.on('state_changed',
        (snapshot) => {
            // complete function
            storage.ref('images').child(image.name).getDownloadURL().then(url => {
                setCarImageURL(url);
            })
        }
        )
    }






  return (



    <div className="container">
    <div className='add-car'>
    <form onSubmit={handleSubmit}>
        <input 
        value={carName}
        placeholder="Input car name"
        onChange={(e) => setCarName(e.target.value)}
        />
        <input 
        value={carPrice}
        placeholder="Input car's rental price"
        onChange={(e) => setCarPrice(e.target.value)}
        />

        <input 
        value={carDescription}
        placeholder="Input car's description"
        onChange={(e) => setCarDescription(e.target.value)}
        />

       <input 
        type="file" 
        onChange={uploadImageHandler}
        />


        <button className='button' >
            Edit Car
        </button>
    </form>
    </div>
    </div>
  )
}


export default EditCar; 


