import React, {
    useState,
    useEffect
} from 'react'; 
import { db, auth} from './firebase';
import {
    Link,
    useParams
 } from 'react-router-dom';
import {
    AiOutlineArrowLeft as AiOut
} from 'react-icons/ai';

const AddBooking = () => {
    const {id} = useParams();

    
    const [name, setName] = useState('');
    const [License, setLicense] = useState('');
    const [email, setEmail] = useState(''); 
    const [addressLine, setAddressLine] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [pickupDate, setPickupDate] = useState('');
    const [pickupTime, setPickupTime] = useState('');
    const [returnDate, setReturnDate] = useState('');
    const [returnTime, setReturnTime] = useState('');
    const [carName, setCarName] = useState('');
    const [carId, setCarId] = useState('');
    const [car, setCar] = useState('');

    useEffect(() => {
        db.collection('inquiries').doc(id).get().then(doc => {
            setName(doc.data().name)
            setLicense(doc.data().License)
            setEmail(doc.data().email)
            setAddressLine(doc.data().addressLine.data.description)
            setPhoneNumber(doc.data().phone)
            setCar(doc.data().car)
            setCarName(doc.data().carName)
            setCarId(doc.data().carId)
        })
        console.log(carId)
    }, [])


    const handleSubmit = (e) => {
        e.preventDefault();
        db.collection('bookings').add({
            name: name,
            License: License,
            email: email,
            addressLine: addressLine,
            phone: phoneNumber,
            car: car,
            carName: carName, 
            carId: carId,
            pickupDate: pickupDate,
            pickupTime: pickupTime,
            returnDate: returnDate,
            returnTime: returnTime,
        })
        .then(() => {
            alert('Booking has been added')
            db.collection('cars').doc(carId).update({
                archived: true
            })
            db.collection('inquiries').doc(id).delete()
        })
        .catch((error) => {
            alert(error.message)
        })
    }



    return (
        <div className='container'>
        <form onSubmit={handleSubmit}>
            <input 
        value={name}
        placeholder="Input customer's name"

        onChange={(e) => setName(e.target.value)}
        />
        <input 
        value={carName}
        placeholder="Input car name"
        onChange={(e) => setCarName(e.target.value)}
        />
        <input 
        value={phoneNumber}
        placeholder="Input customer's phone-number"
        onChange={(e) => setPhoneNumber(e.target.value)}
        />

        <input
        value={addressLine}
        placeholder="Input customer's Address"
        onChange={(e) => setAddressLine(e.target.value)}
        />

        <input 
        value={email}
        placeholder="Input customer's Email"
        onChange={(e) => setEmail(e.target.value)}
        />

        <input
        type='date'
        value={pickupDate}
        placeholder="Input pickup date"
        onChange={(e) => setPickupDate(e.target.value)}
        />
        
        <input 
        type='time'
        value={pickupTime}
        placeholder="Input pickup time"
        onChange={(e) => setPickupTime(e.target.value)}
        />

        <input
        type='date'
        value={returnDate}
        placeholder="Input return date"
        onChange={(e) => setReturnDate(e.target.value)}
        />

        <input
        type='time'
        value={returnTime}
        placeholder="Input return time"
        onChange={(e) => setReturnTime(e.target.value)}
        />


        <a href='/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/bookings'>
        <button className='button' type='submit'>
            Add Booking
        </button>
        </a>
            </form>
        </div>

  )
}

export default AddBooking