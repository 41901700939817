import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDzWCL_J0y43rQxzPKA17eNtOlPGPRhANE",
    authDomain: "vahaa-rentals.firebaseapp.com",
    projectId: "vahaa-rentals",
    storageBucket: "vahaa-rentals.appspot.com",
    messagingSenderId: "1092745961605",
    appId: "1:1092745961605:web:bf677f6f78bace4cfd89ec",
    measurementId: "G-60P12335LY"
  };


  const app = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();



  const db = firebase.firestore();
  const auth = firebase.auth();
  const storage = firebase.storage();


  export { db, auth, storage };