import React, {useState, useEffect} from 'react'
import {db} from '../firebase'; 
import {
    AiOutlineArrowLeft as AiOut
} from 'react-icons/ai'
import {Link} from 'react-router-dom';
import './style.css';
import {motion} from 'framer-motion';
import logo from './logo250.png';

const Cars = () => {

    const [inquiries, setInquiries] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        db.collection('inquiries').onSnapshot(snapshot => (
            setInquiries(snapshot.docs.map(doc => ({
                id: doc.id,
                inquire: doc.data()
            })))
        ))
    }, []);

    useEffect(() => {
        if(inquiries.length > 0) {
            setLoading(false);
        }
    }, [inquiries])
    

  return (
    <>
       {
          loading ? (
          <motion.div 
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 1}}
          className='container'>
            <img src={logo}  
            style={{width: '250px', display: 'block', margin: 'auto'}}/>
          </motion.div>
          ) : (
    <>
        <div className="header">
        <div className='back'>
            <Link to='/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51'>
                <AiOut className='icon' />
            </Link>
        </div>
        <h1
        style={{
            textAlign: 'center', 
            marginRight: '46%'
        }}
        >Inquiries</h1>
    </div>
    <div className="Container">
        
            {
                inquiries && inquiries.map(({id, inquire}) => (
                    <div className='inquire' key={id}>
                        <img src={inquire.License} alt="License" style={{
                            width: 320, height: 200
                        }} />
                        <p className="inquireText" style={{
                            fontWeight: "200", fontSize: 36
                        }}>{inquire.name}</p>
                        <p className="inquireText" style={{
                            color: "#AEB5BC", fontSize: 14
                        }}>Car: {inquire.carName}</p>
                        <p className="inquireText" style={{
                            color: "#AEB5BC", fontSize: 14
                        }}>{inquire.addressLine.data.description}</p>
                        <p className="inquireText" style={{
                            color: "#AEB5BC", fontSize: 14
                        }}>{inquire.email}</p>
                        <p className="inquireText" style={{
                            color: "#AEB5BC", fontSize: 14
                        }}>{inquire.phone}</p>
                        
                        <div className='icons'>
                            <Link to={`f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/add-booking/${id}`}><button className='button'>Book Car</button></Link>
                        </div>
            
                    </div>

                ))
            }

        </div>
    </>
    )}
    </>
  )
}

export default Cars;