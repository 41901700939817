import React, {useEffect, useState} from 'react'
import {db, auth} from '../firebase'; 
import {
    AiOutlineDelete,
    AiOutlineEdit,
    AiOutlinePlus, 
    AiOutlineArrowLeft as AiOut
} from 'react-icons/ai'
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion'; 
import logo from './logo250.png';

const Cars = () => {

    const [cars, setCars] = useState([])
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        setLoading(false)
        auth.onAuthStateChanged((user, e) => {
            if (user, e) {
              e.preventDefault();
                db.collection('users').doc(auth.currentUser.uid).get().then((doc) => {
                    if (doc.data().role === 'admin') {
                        window.location.href = "/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/cars" 
                    } else {
                        alert('You are not an admin')
                        auth.signOut();
                        window.location.href = '/login'
                    }
                })
            } if (user == null) {
                window.location.href = '/login'
            }
        })
    }, [])
    

    useEffect(() => {
        db.collection('cars').onSnapshot(snapshot => (
            setCars(snapshot.docs.map(doc => ({
                id: doc.id,
                car: doc.data()
            })))
        ))
    }, []);
     
    useEffect(() => {
        if(cars.length > 0) {
            setLoading(false);
        }
    }, [cars])
    


  return (
  
    <>
      {
          loading ? (
          <motion.div 
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 1}}
          className='container'>
            <img src={logo}  alt="vahaa-logo"
            style={{width: '250px', display: 'block', margin: 'auto'}}/>
          </motion.div>
          ) : (
      <>
        <div className="header">
        <div className='back'>
            <Link to='/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51'>
                <AiOut className='icon' />
            </Link>
        </div>
        <h1>Cars</h1>

        <div className="add">
            <Link to="f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/addcar">
                <AiOutlinePlus className='icon' />
            </Link>
        </div>


    </div>
    <div className="Container">
        
            {
                cars && cars.map(({id, car}) => (
                    <div className='widget' key={id}>
                        <h1 className='name'>{car.name}</h1>
                        <h1 className='price'>${car.price} per day</h1>
                        <img 
                        src={car.image} 
                        alt='car image' 
                        style={{width: '45%', height: '30%', borderRadius: '15px'}}/> 
                        <div className='icons'> 
                            <Link to={`f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/editcar/${id}`}>
                                <AiOutlineEdit />
                            </Link>
                            <Link to={`f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/deletecar/${id}`}>
                                <AiOutlineDelete />
                            </Link>
                        </div>
                    </div>

                ))
            }

        </div>
    </>
          )
        }
    </>
 
    
  )
}

export default Cars;