import React, {
    useState,
    useEffect
} from 'react'; 

import {
    Link, 
} from 'react-router-dom';

import {
    AiOutlineArrowLeft
    as AiOut
} from 'react-icons/ai'; 
import { db } from './firebase';
import {motion} from 'framer-motion';
import logo from './logo250.png';

const Booking = () => {


    const [bookings, setBookings] = useState('');
    const [loading, setLoading] = useState(true);

    
    useEffect(() => {
        db.collection('bookings').onSnapshot(snapshot => (
            setBookings(snapshot.docs.map(doc => ({
                id: doc.id,
                booking: doc.data()
            }))
            )
        ))
    
    }, [])

    useEffect(() => {
        if(bookings.length > 0) {
            setLoading(false);
        }
    }, [bookings])
    



  return (
    <>
       {
          loading ? (
          <motion.div 
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 1}}
          className='container'>
            <img src={logo} alt="vahaa-logo"
            style={{width: '250px', display: 'block', margin: 'auto'}}/>
          </motion.div>
          ) : (
    <>
    <div className="header">
    <div className='back'>
        <Link to='/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51'>
            <AiOut className='icon' />
        </Link>
    </div>
    <h1
    style={{
        textAlign: 'center',
        marginRight: '46%'
    }}
    >Bookings</h1>
    </div>
    <div className='Container'>
    {
        bookings && bookings.map(({id, booking}) => (
            <div className='inquire' key={id}>
                <img src={booking.License} 
                style={{
                    width: 320, height: 200
                }}
                alt={`${booking.name}'s License`} />
                <p className="inquireText" style={{
                    fontWeight: "200", fontSize: 36
                }}>{booking.name}</p>
                <p className="inquireText" style={{
                    color: "#AEB5BC", fontSize: 14
                }}>Car Booked: {booking.carName}</p>
                <p className="inquireText" style={{
                    color: "#AEB5BC", fontSize: 14
                }}>Address: {booking.addressLine}</p>
                <p className="inquireText" style={{
                    color: "#AEB5BC", fontSize: 14
                }}>Email: {booking.email}</p>
                <p className="inquireText" style={{
                    color: "#AEB5BC", fontSize: 14
                }}>Pickup Date: {booking.pickupDate}, {booking.pickupTime}</p>
               
                <p className="inquireText" style={{
                    color: "#AEB5BC", fontSize: 14
                }}>To Be Returned: {booking.returnDate}, {booking.returnTime}</p>
                    <Link to={`f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/add-record/${id}`} >
                        <button className='button'>Car Returned</button>
                    </Link>
                    <Link to={`f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/cancel-booking/${id}`} >
                        <button className='button'>Cancel Booking</button>
                    </Link>
            </div>
        ))
    }
    </div>
    </>
          )
          }
    </>
  )
}

export default Booking; 