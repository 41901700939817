import React, {useEffect} from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from 'react-router-dom'; 
import AddCar from './components/AddCar';
import AddRecord from './components/AddRecord';
import DeleteCar from './components/DeleteCar';
import Home from './components/Home';
import Inquiries from './components/Inquiries';
import Records from './components/Records'
import Cars from './components/Cars';
import EditCar from './components/EditCar';
import Booking from './components/Booking';
import AddBooking from './components/AddBooking';
import DeleteBooking from './components/DeleteBooking';
import Login from './components/Login';
import { auth, db } from './firebase';

function App() {

    
    
    return(
        <Router>
            <Routes>
                <Route exact path="/login" element={<Login/>}/>
                <Route exact path="/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51" element={<Home/>} />
                <Route exact path="/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/cars/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/addcar" element={<AddCar/>} />
                <Route exact path="/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/bookings/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/add-record/:id" element={<AddRecord/>} />
                <Route exact path="/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/inquiries/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/add-booking/:id" element={<AddBooking/>} />
                <Route exact path="/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/bookings/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/cancel-booking/:id" element={<DeleteBooking/>} />
                <Route exact path="f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/cars/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/deletecar/:id" element={<DeleteCar/>} />
                <Route exact path="/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/cars/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/editcar/:id" element={<EditCar/>} />
                <Route exact path="f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/inquiries" element={<Inquiries/>} />
                <Route exact path="f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/records" element={<Records/>} />
                <Route exact path="f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/cars" element={<Cars/>} />
                <Route exact path="f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/bookings" element={<Booking/>} />
            </Routes>
        </Router>

    )
}

export default App; 

