import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import {db, storage} from '../firebase'
import {
    AiOutlineArrowLeft as AiOut
} from 'react-icons/ai';
import './style.css';

function AddCar() {

    const [id, setId] = useState('');
    const [carName, setCarName] = useState('');
    const [carPrice, setCarPrice] = useState('');
    const [carImage, setCarImage] = useState(false);
    const [carImageURL, setCarImageURL] = useState('');
    const [carDescription, setCarDescription] = useState('');
    const [carError, setCarError] = useState('');


    const addCarHandler = (e) => {
        e.preventDefault();
        if(carName === '' || carPrice === '' || carDescription === '' || carImage === false) {
            setCarError('Please fill all the fields');
        } else {
        db.collection('cars').add({
            name: carName,
            price: carPrice,
            image: carImageURL,
            description: carDescription
        })
        setCarName('');
        setCarPrice('');
        setCarImage(false);
        setCarImageURL('');
        setCarDescription('');
    }
    }
    const uploadImageHandler = (e) => {
        e.preventDefault();
        const image = e.target.files[0];
        const uploadTask = storage.ref(`images/${image.name}`).put(image);
        uploadTask.on('state_changed',
        (snapshot) => { 
            // complete function
            storage.ref('images').child(image.name).getDownloadURL().then(url => {
                setCarImageURL(url);
            })
        }
        )
    }

  return (
<>
<div className="header">
        <div className='back'>
            <Link to='/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/cars'>
                <AiOut className='icon' />
            </Link>
        </div>
        <h1
        style={{
            textAlign: 'center', 
            marginRight: '46%'
        }}
        >Add Car</h1>
        </div>
        <div
        className='container'>
        <form onSubmit={addCarHandler}>
            <input type="text" placeholder="Car Name" value={carName} onChange={(e) => setCarName(e.target.value)} />
            <input type="text" placeholder="Car Price" value={carPrice} onChange={(e) => setCarPrice(e.target.value)} />
            <input type="text" placeholder="Car Description" value={carDescription} onChange={(e) => setCarDescription(e.target.value)} />
            <input type="file" onChange={uploadImageHandler} />
            <p
            style={{color: 'red'}}>{carError}</p>
            <a href='/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/cars'>
            <button className='button' type="submit">Add Car</button>
            </a>
        </form>
        </div>
</>
    );
}

  

export default AddCar;