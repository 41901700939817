import React from 'react'
import {db} from '../firebase'; 
import { useParams, Navigate } from 'react-router-dom'
import {
    AiOutlineArrowLeft as AiOut
} from 'react-icons/ai'; 
import { Link } from 'react-router-dom';
import './style.css'


function AddRecord() {

    const {id} = useParams();

    const [ name, setName ] = React.useState('')
    const [ License, setLicense ] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [ addressLine, setAddressLine ] = React.useState('')
    const [ phoneNumber, setPhoneNumber ] = React.useState('')
    const [ pickupDate, setPickupDate ] = React.useState('')
    const [pickupTime, setPickupTime] = React.useState('')  
    const [ returnDate, setReturnDate ] = React.useState('')
    const [returnTime, setReturnTime] = React.useState('')
    const [carName, setCarName] = React.useState('')
    const [car, setCar] = React.useState('')
    const [carId, setCarId] = React.useState('')

    React.useEffect(() => {
        db.collection('bookings').doc(id).get().then(doc => {
            setName(doc.data().name)
            setLicense(doc.data().License)
            setEmail(doc.data().email)
            setAddressLine(doc.data().addressLine)
            setPhoneNumber(doc.data().phone)
            setCar(doc.data().car)
            setCarName(doc.data().carName)
            setCarId(doc.data().carId)
        })
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        db.collection('records').add({
            name: name,
            License: License,
            email: email,
            addressLine: addressLine, 
            phone: phoneNumber,
            car: car,
            pickupDate: pickupDate,
            pickupTime: pickupTime,
            returnDate: returnDate,
            returnTime: returnTime,
        })
        .then(() => {
            db.collection('cars').doc(carId).update({
                archived: false
            })
        })
        .then(() => {
            alert('Record added successfully')
            Navigate('/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/records');
        })
        .catch((error) => {
            alert(error.message)
        })
    }




  return (

<>
    <div className="header">
    <div className='back'>
        <Link to='/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/bookings'>
            <AiOut className='icon' />
        </Link>
    </div>
    <h1
    style={{
        textAlign: 'center', 
        marginRight: '46%'
    }}
    >Add Record</h1>
    </div>
    <div className="container">
    <form onSubmit={handleSubmit}
    >
        <input 
        value={name}
        placeholder="Input customer's name"
        onChange={(e) => setName(e.target.value)}
        />
        <input 
        value={carName}
        placeholder="Input car name"
        onChange={(e) => setCarName(e.target.value)}
        />
        <input 
        value={phoneNumber}
        placeholder="Input customer's phone-number"
        onChange={(e) => setPhoneNumber(e.target.value)}
        />

        <input
        value={addressLine}
        placeholder="Input customer's Address"
        onChange={(e) => setAddressLine(e.target.value)}
        />

        <input 
        value={email}
        placeholder="Input customer's Email"
        onChange={(e) => setEmail(e.target.value)}
        />

        <input
        type='date'
        value={pickupDate}
        placeholder="Input pickup date"
        onChange={(e) => setPickupDate(e.target.value)}
        />
        
        <input 
        type='time'
        value={pickupTime}
        placeholder="Input pickup time"
        onChange={(e) => setPickupTime(e.target.value)}
        />

        <input
        type='date'
        value={returnDate}
        placeholder="Input return date"
        onChange={(e) => setReturnDate(e.target.value)}
        />

        <input
        type='time'
        value={returnTime}
        placeholder="Input return time"
        onChange={(e) => setReturnTime(e.target.value)}
        />

        <button className='button'>
            Add Record
        </button>
    </form>
    </div>
  </>
  )
}

export default AddRecord; 

