import React from 'react'
import {auth, db } from '../firebase'
import { Navigate } from 'react-router-dom'
import './style.css'
import Logo from './logo250.png'
const Login = () => {
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [error, setError] = React.useState('')
     


    const handleSubmit = (e) => {
        if ( email === '' || password === '') {
            setError('Please enter username and password')
        } else {
        e.preventDefault()
        auth
            .signInWithEmailAndPassword(email, password)
            .then(() => {
                db.collection('users').doc(auth.currentUser.uid).get().then((doc) => { 
                    if (doc.data().role === 'admin') {
                        window.location.href = '/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51'

    
                    } else {
                    setError('You are not an admin')
                    }
                }
            )
            .catch((error) => {
                setError(error.message)
            })
    })
    }
}


  return (
    <div className="container">
        <img 
            src={Logo}
            alt="vahaa-rentals"
            style={{width: '120px', height: '100px', marginBottom: '50px'}}
        />

        <form onSubmit={handleSubmit}>
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
            />
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
            />
            <button
            className="button"
            style={{
                margin: '0 auto',
                alignSelf: 'center', 
                display: 'block',
                marginLeft: 'auto',
            }}
            type="submit">Login</button>
        </form>
        {error && <p style={{color: 'red'}}>{error}</p>}
    </div>
  )
}

export default Login;