import React, {useState, useEffect} from 'react'; 
import './style.css';
import {
  IoCarOutline
} from 'react-icons/io5';
import {
  AiOutlineBook, 
  AiFillFolder
} from 'react-icons/ai';
import {
  RiMessage2Fill
} from 'react-icons/ri';
import {
  Link, 
  Navigate
} from 'react-router-dom';
import {auth, db} from '../firebase';
import {motion} from 'framer-motion';
import logo from './logo250.png';

const Home = (props) => {

  const [loading, setLoading] = useState(true);

  
  useEffect(() => {
    setLoading(false)
    auth.onAuthStateChanged((user, e) => {
        if (user, e) {
          e.preventDefault();
            db.collection('users').doc(auth.currentUser.uid).get().then((doc) => {
                if (doc.data().role === 'admin') {
                    window.location.href = "/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51" 
                } else {
                    alert('You are not an admin')
                    auth.signOut();
                    window.location.href = '/login'
                }
            })
        } if (user == null) {
            window.location.href = '/login'
        }
    })
}, [])



  return (
    <>
       {
          loading ? (
          <motion.div 
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 1}}
          className='container'>
            <img src={logo} 
            style={{width: '250px', display: 'block', margin: 'auto'}}/>
          </motion.div>
          ) : (
    
    <>
    <div className="header">
      <h1
      style={{
        textAlign: 'center', 
        position: 'absolute',
      }}
      >Home</h1>

      <button className='signOutButton'
      onClick={() => auth.signOut()}
      sty
      >Sign Out</button>
   
    </div>
    <div className="container">
      <div className="content">
      <Link to="f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/cars">
        <IoCarOutline style={{
          fontSize: '50px',
          color: '#fff'
        }} />
      </Link>
      <Link to='f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/inquiries'>
        <RiMessage2Fill style={{
          fontSize: '50px', 
          color: '#fff'
        }} />
      </Link>
      <Link to='f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/bookings'>
        <AiOutlineBook style={{
          fontSize: '50px', 
          color: '#fff'
        }} />
      </Link>
      <Link to='f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/records'>
        <AiFillFolder style={{
          fontSize: '50px',
          color: '#fff'
        }} />
      </Link>
    </div>
    </div>
    </>
    )
    }
    </>
  
  )
}

export default Home;