import React from 'react'
import {db} from '../firebase';
import { useParams, Link } from 'react-router-dom'
import {motion} from 'framer-motion';
import logo  from './logo250.png';
import './style.css'

const DeleteBooking = () => {

    const {id} = useParams();
    const [name, setName] = React.useState('');
    const [loading, setLoading] = React.useState(true);

    const handleDelete = () => {
        db.collection('bookings').doc(id).delete().then(() => {
            alert('Booking deleted successfully')
        })
    }

    React.useEffect(() => {
        db.collection('bookings').doc(id).get().then(doc => {
            setName(doc.data().name);
            setLoading(false);
        })
    }, [id])


  return (
    <>
     
    {
      loading ? (
      <motion.div 
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        transition={{duration: 1}}
      className='container'>
        <img src={logo} alt="vahaa-logo"
        style={{width: '250px', display: 'block', margin: 'auto'}}/>
      </motion.div>
      ) : (
      <>
      <div className="header">
        <h1>Cancel Booking</h1>
      </div>
      <div className="container">
        <div className='widget'>
          <h1>Are you sure you want to cancel {name}'s booking? (this action is not reversable)</h1>
          <Link to='f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/bookings'>
            <button
          className='button'
          onClick={handleDelete}>Cancel Booking
          </button>
          </Link>
          <Link to='f6b51c758f4136fdb90eec280b0692af0f435b2d246dc5e79bea8d97af0bcc51/bookings'>
            <button className='button'>Back</button>
          </Link>
         
        </div>
        </div>
        </>
      )
    }

</>
  )
}

export default DeleteBooking;